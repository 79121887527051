import React, { useState, useEffect, useRef } from 'react';
import { useNavigate,useLocation, Link } from 'react-router-dom';
import Image from "../assets/quiz.png";
import Blog from "../assets/QuizBlog.png";
import "../assets/css/navbar.css"
import sponsored from './sponsored';
import hostInfo from '../Servies';
// import Sidebar from './Sidebar';
import { LuMenu } from "react-icons/lu";
import { MdNotificationsActive } from "react-icons/md";
import Cookies from 'js-cookie';
import coinCookieName from "../Servies"

function Home() {
  const [quizCategories, setQuizCategories] = useState([]);
  const navigate = useNavigate();
  const [introBoxVisible] = useState(true); 
  const location = useLocation();
  const totalCoins = location.state ? location.state.totalCoin : 0;
  const [selectedQuizIndex, setSelectedQuizIndex] = useState(null);
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [datas, setDatas] = useState(null);
  const [cookie,setcookie] = useState()
  const topOfPageRef = useRef(null);

useEffect(() => {
  setcookie(Cookies.get(coinCookieName.coinCookieName))
  const fetchData = async () => {
    try {
      const response = await fetch(hostInfo.hostInfo + 'api/articles', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': 'quiz@234',
        },
      });

      const data = await response.json();

      if (data.success) {
        setDatas(data.articles);
      } else {
        console.error('Error fetching articles:', data.error);
      }
    } catch (error) {
      console.error('Error fetching articles:', error);
    }
  };

  fetchData();
}, []);  

  useEffect(() => {
     // Fetch quiz categories from your API with API key in headers
     fetch(hostInfo.hostInfo + 'api/quiz-categories', {
       method: 'GET', 
       headers: {
         'Content-Type': 'application/json',
         'x-api-key': 'quiz@234',
       },
     })
      .then(response => response.json())
      .then(data => {
        
        if (data.success) {
          setQuizCategories(data.quizCategories);
        } else {
          console.error('Error fetching quiz categories:', data.error);
        }
      })
      .catch(error => {
        console.error('Error fetching quiz categories:', error);
      });
  }, []);

  const handlePlayNowClick = (category) => {
    // console.log(category.win_coins)
    // console.log(cookie)
    topOfPageRef.current.scrollIntoView({ behavior: 'auto' });
   if(category.entry_coins<=cookie){

     navigate(`/playnow/${category.quiz_category}/${category.quiz_id}`, {
       state: {
         quizCategories:category,
         quiz_pic_url: category.quiz_pic_url,
         coin_text: category.coin_text ,  
         win_coins: category.win_coins,
         entry_coins: category.entry_coins,
         quiz_category: category.quiz_category,
         num_of_questions:category.num_of_questions,
         timer_value:category.timer_value,
         correct_ans_point:category.correct_ans_point,
         wrong_ans_point:category.wrong_ans_point
       },
     });
   }
   else if (category.entry_coins>cookie){
    navigate(`/`)
   }
  };

  const handleEarnMoreCoinsClick = () => {
    navigate('/');
  };
  const toggleSidebar = (index) => {
    setSelectedQuizIndex(index);
    setSidebarOpen(true);
  };
  
  // const closeSidebar = () => {
  //   setSelectedQuizIndex(null);
  //   setSidebarOpen(false);
  // };

  const getImage = (imagename) => {
    if(imagename.includes("http")){
      return imagename
    }else {
      return `/assets/images/icon/${imagename}`;
    }
  };
  
  const getBlog = (imagename) => {
    if(imagename.includes("http")){
      return imagename
    }else {
      return `../assets/QuizBlog.png/${imagename}`;
    }
  };

  return (
    <>
    {/* <Navbar /> */}
    <div className="home"  ref={topOfPageRef} style={{ top: 0 }} tabIndex="-1">
    <header className="header_header__R3wYf">
      <div className="header_logo__4Zn2n">
        <nav>
        <label htmlFor="menu-control" className="header_sidebarToggle__Z8XaD" onClick={() => toggleSidebar()}>
            <LuMenu style={{width:"28",height:"28",margin:"5px"}}/>
          <img
            title="Quizpopa"
            alt="Quizpopa"
            fetchPriority="high"  
            width="auto"
            height="35"
            decoding="async"
            data-nimg="1"
            src={require("../assets/Quizpopa_logo.png")} 
            style={{ color: 'transparent', margin:"1px"}}
          />  
          </label>
        </nav>
      </div>
      <div className="header_headerRight__beEHK" style={{marginTop:"-7px"}}>
        <div className="header_totalCoins__4w69L" style={{marginRight:"5px"}}>
          <img
                title="quizpopa_coin"
                alt="coin"
                loading="lazy"
                width="19"
                height="19"
                decoding="async"
                data-nimg="1"
                srcSet="https://pngimg.com/uploads/coin/coin_PNG36871.png"
                style={{ color: 'transparent' ,marginLeft:"5px"}}
            />
            <div className='heder_coins' style={{paddingLeft:"8px", textAlign:'center', margin:"5px 2px"}}> {cookie} Coins</div>
        </div>
        <div className="header_notification__roink header_ring__7Ayqv">
          <MdNotificationsActive  style= {{ width:"20", height:"23"}}/>
        </div>
      </div>
    </header>
      <div className="intro-box">
        <div className="intro-texts">
          <h1 className="intro-title">Quizzes</h1>
          <p className="intro-description">Choose the quiz you want to solve</p>
        </div>
        
        <div   style={{display:"flex", justifyContent:"center"}}>
        <img
          src={Image}
          style={{ padding: "10px 5px" }}
          alt="Intro Icon"
          className="intro-icon"
        />
        </div>
        
        <div>
        {introBoxVisible && (
            <button className="earn-more-coins-button"  onClick={handleEarnMoreCoinsClick}>
              Earn More Coins </button>
          )}
          </div>
      </div>

      {/* Ad Section */}
      <div>
      {sponsored.items.toLowerCase() === 'true' && (
        <div className="page_ad">
          <div className="page_sponsored">{sponsored.title}</div>
        </div>
      )}
    </div>

       {quizCategories.map((category,index) => (
     <div className="quizList_quizCard" style={{ marginTop: "10px" }} key={category.quiz_id}>
        <div className="quizList_cardContent" key={index}>      
          <div className="quizList_thumbnialImg">
            <img
              alt= {`Play ${category} Online Quiz`}
              title= {`Play ${category} Online Quiz`}
              fetchpriority="high"  
              width="60"
              height="60"
              decoding="async"
              data-nimg="1"
              // src={ `/assets/images/icon/${category.quiz_pic_url}`}
              src={category.quiz_pic_url ? getImage(category.quiz_pic_url) : Image}
              style={{ color: 'transparent' }}
            />
          </div>
          <div className="quizList_quizNameStatus">
            <div className="quizList_categoryLiveDiv" style={{marginBottom:"10px",marginTop:"8px"}}>
              <span className="quizList_categories">{category.quiz_category}</span>
              <span className="quizList_statusLive"><div className="liveCircle"></div>Live</span>
            </div>
            <h3 style={{color:"white",display:"flex",justifyContent:"center",marginBottom:"5px"}}>
              {category.coin_text} {category.win_coins}
              <img
                title="quizpopa_coin"
                alt="coin"
                loading="lazy"
                width="19"
                height="19"
                decoding="async"
                data-nimg="1"
                srcSet="https://pngimg.com/uploads/coin/coin_PNG36871.png"
                style={{ color: 'transparent' ,marginLeft:"5px"}}
              />
            </h3>
            <p style={{color:"var(--light-color)",fontWeight:"lighter",fontSize:"12px"}}>Winner announcement @ {new Date().getHours() }:{ new Date().getMinutes()}</p>
          </div>
        </div>
        <div className="quizList_cardFooter">
          <p>
            <span style={{color:"white",display:"flex",justifyContent:"center"}}>
              <span className="quizList_spacer" style={{color:"white",marginRight:"5px"}}>Entry: {category.entry_coins} </span>
              <img
                title="quizpopa_coin"
                alt="coin"
                loading="lazy"
                width="16"
                height="16"
                decoding="async"
                data-nimg="1"
                srcSet="https://pngimg.com/uploads/coin/coin_PNG36871.png"
                style={{ color: 'transparent' }}
              />{' '}
              <strong style={{color:"var(--light-color)",marginLeft:"15px",marginRight:"10px"}}>{Math.floor(Math.random() * (10000 - 5000 + 1)) + 5000} Users Playing</strong> 
            </span>
          </p>
        <button
          className="quizList_btn quizList_btnSmall "
          onClick={() => handlePlayNowClick(category)}>
          play
        </button>
        </div>  
    </div>  
       ))}
        {/* Ad Section */}
      <div>
      {sponsored.items.toLowerCase() === 'true' && (
        <div className="page_ad">
          <div className="page_sponsored">{sponsored.title}</div>
        </div>
      )}
    </div>

        {/* Trending Articles Section */}
        <div className="trending-articles">
          <div className="section-title" style={{color:"var(--light-color)" ,fontSize:"25px",fontWeight:"bold",     margin:"10px 15px", }}>Trending Articles</div>
            
              {/* {datas && datas.map((i)=>{return(
              <>
              <Link to={`/blog/${i.title}`} style={{ textDecoration: 'none' }}>

            <div className="article-content"  style={{ }}>
              <img
              src={i.image  ? getBlog (i.image) : Blog}
              alt=""
              className="article-image" />

            <div className="article-text">
              <h3 className="article-title">{i.title}</h3>
              <p className="article-description">
               {i.descriptions}
              </p>
            </div> 
      </div>
      </Link>
    </>
     )})} */}
     {datas && datas.map((i, index) => (
  <React.Fragment key={index}>
    <Link to={`/blog/${i.title}`} style={{ textDecoration: 'none' }}>
      <div className="article-content" key={i.title}>
        <img
          src={i.image ? getBlog(i.image) : Blog}
          alt=""
          className="article-image"
        />
        <div className="article-text">
          <h3 className="article-title">{i.title}</h3>
          <p className="article-description">{i.descriptions}</p>
        </div>
      </div>
    </Link>
  </React.Fragment>
))}



    </div>
</div> 
    {/* Sidebar */}
    {/* <Sidebar isOpen={isSidebarOpen} onClose={closeSidebar} selectedQuizIndex={selectedQuizIndex} /> */}
    </>
  );
}

export default Home;























