import Intro from "./components/Intro";
import Play from "./components/Play";
import Home from "./components/Home";
import PlayNow from "./components/Playnow";
import Quiz from "./components/Quiz";
import Result from "./components/Result";
import Error from "./components/Error";
import Blog from "./components/Blog";  // Import the Blog component
import { Routes, Route } from "react-router-dom";

function App() {
  return (
    <div className="app-container"  >
      <div className="quiz-box" style={{marginTop:"-50px",marginBottom:"-50px"}}>
        <Routes>
          <Route path="/" element={<Intro/>} />
          <Route path="/play" element={<Play/>} />
          <Route path="/home" element={<Home />} />
          <Route path="/playnow/:category/:id" element={<PlayNow />} />
          <Route path="/quiz/:category/:id" element={<Quiz />} />
          <Route path="/:category/result" element={<Result />} />
          <Route path="*" element={<Error />} />
          <Route path="/blog/:title" element={<Blog/>} />

        </Routes>
      </div>
    </div>
  );
}

export default App;
