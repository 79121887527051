// Node api code
import React, { useState, useEffect, useRef } from 'react';
import quizContent from './content';
import sponsored from './sponsored';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import hostInfo from '../Servies';
import coinCookieName from "../Servies"

const Intro = () => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [isCorrect, setIsCorrect] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [totalCoins, setTotalCoins] = useState(0);
  const navigate = useNavigate();
  const [correctAnswersCount, setCorrectAnswersCount] = useState(0);
  const topOfPageRef = useRef(null);


  var total = 0
  // console.log(totalCoins)
  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const response = await fetch(hostInfo.hostInfo +'api/random-rows', {
          method: 'GET', 
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': 'quiz@234',
          },
        });        
        const data = await response.json();    
        // console.log(document.cookie)
        setQuestions(data.rows);
        document.cookie = data.token;
      } catch (error) {
        console.error('Error fetching questions:', error);
      }
    };

    fetchQuestions();
  }, []);
  
  // ###############################################
  // useEffect(() => {
  //   if (correctAnswersCount === questions.length) {
  //     // console.log("TotalCoins before update ", totalCoins);

  //     // All questions answered correctly, update the "totalCoins" cookie with the new value
  //     const existingTotalCoins = parseInt(Cookies.get("totalCoins")) || 0;
  //     // console.log('existingTotalCoins', existingTotalCoins)

  //     const updatedTotalCoins = existingTotalCoins + totalCoins;
  //     // console.log('updatedTotalCoins', updatedTotalCoins)

  //     Cookies.set("totalCoins", updatedTotalCoins);
  //     // console.log("totalCoins after update", updatedTotalCoins);
  //   }
  // }, [correctAnswersCount, totalCoins]);
// ######################################

    const [allQuestionsAnswered, setAllQuestionsAnswered] = useState(false);
    useEffect(() => {
      if (currentQuestion === questions.length && isCorrect !== null) {
        // console.log("total coin to pass ", totalCoins);
        setAllQuestionsAnswered(true);
      }
    }, [currentQuestion, isCorrect]);
  
    useEffect(() => {
      if (allQuestionsAnswered) {
        topOfPageRef.current.scrollIntoView({ behavior: 'auto' });
        navigate('/play', { state: {totalCoins , isCorrect } });
      }
    }, [allQuestionsAnswered, isCorrect, navigate]);
  
    const handleAnswerClick = (answer) => {
      const isAnswerCorrect = answer === questions[currentQuestion]?.right_ans;
      setSelectedAnswer(answer);
      setIsCorrect(isAnswerCorrect);
  
      if (isAnswerCorrect) {
        // Correct answer, update coins and increment correctAnswersCount
        // setTotalCoins((prevCoins) => prevCoins + 100);
        total = total + 100
        setCorrectAnswersCount((count) => count + 1);
        setTotalCoins((pre) => pre + total)
      }
      
        if(Cookies.get(coinCookieName.coinCookieName)){
      let value = parseInt(Cookies.get(coinCookieName.coinCookieName))
      Cookies.set(coinCookieName.coinCookieName,value + total)
      
    }
    else{
      Cookies.set(coinCookieName.coinCookieName, total)
    }
      setTimeout(handleNextQuestion, 1000);
    };

    const handleNextQuestion = () => {
      if (currentQuestion + 1 < questions.length) {
        setCurrentQuestion(currentQuestion + 1);
        setSelectedAnswer(null);
        setIsCorrect(null);
      } else {
        // Set allQuestionsAnswered to true when all questions are answered
        setAllQuestionsAnswered(true);
      }
    };
    
  const parseAnswers = (answersString) => {
    try {
      const formattedString = (answersString ?? '').replace(/["{}]/g, '');
      const parsedAnswers = formattedString.split(',');
      return parsedAnswers;
    } catch (error) {
      console.error('Error parsing answers:', error);
      return [];
    }
  };

  const currentQuestionData = questions[currentQuestion] || {};
  const answersArray = parseAnswers(currentQuestionData.answers).map(answer => answer.trim());

  return (
    <div className="page_container"  ref={topOfPageRef} style={{ top: 0 }} tabIndex="-1">
      {/* Ad Section */}
      <div>
      {sponsored.items.toLowerCase() === 'true' && (
        <div className="page_ad">
          <div className="page_sponsored">{sponsored.title}</div>
        </div>
      )}
    </div>
      <div className="quizCard">
        <div className="quizCard_header">
          <h1>Quick Start!</h1>
          <p>Answer 2 questions and win up to 200 coins</p>
        </div>
        <div className="quizCard_body">
          {currentQuestion < questions.length ? (
            <div className="question">
              <div className="quizCard_qaOptions">
                <div className="questionHeader_questionHeading">
                  <div className="questionHeader_inner">
                    <div>
                      <span>{currentQuestion + 1}</span>
                      <span>/</span>
                      <span> <strong>{questions.length}</strong></span>
                    </div>
                    <div className="questionHeader">Question</div>
                  </div>
                </div>
                <h3>{currentQuestionData.question}</h3>
                <ul>
                  {answersArray.map((option, index) => (
                    <li key={index}>
                      <button
                        className={selectedAnswer === option ? (isCorrect ? 'correct' : 'incorrect') : ''}
                        onClick={() => handleAnswerClick(option)}
                        disabled={selectedAnswer !== null}
                      >
                        {option}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </div>
      <section className="contentList_contentList">
        <h2>{quizContent.title}</h2>
        <ul>
          {quizContent.items.map((item, index) => (
            <li key={index} style={{marginBottom:"-10px"}}>{item}</li>
          ))}
        </ul>
      </section>

    </div>
  );
};

export default Intro;



