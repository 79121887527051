import React from "react";
import {Link, useLocation, useNavigate } from "react-router-dom";
import Image from "../assets/result_bg.png";
import coinCookieName from "../Servies"
import Cookies from 'js-cookie';

function Result() {
  const navigate = useNavigate();
  const location = useLocation();
  const allAnswers = location.state.answers;
  const allQuestions = location.state.data;
  const category = location.state.categoryname;
  const finalScore = location.state.score;
  // console.log(finalScore)
  const quizCategories = location.state.quizCategories;
  const totalQuestions = allQuestions.length;
  const wrongAnswers = totalQuestions - allAnswers;
  const correctAnswers = allAnswers;
  
  let value = parseInt(Cookies.get(coinCookieName.coinCookieName))

  const hadleclick = ()=>{
    Cookies.set(coinCookieName.coinCookieName,value+finalScore/2)
    navigate(`/home`) 
  }

  return (
    <div className="result">
       <div className="row headrow">
      <div className="quizhead">
        {/* Use Link instead of <a> */}
        <Link className="quizhead" to="/home">
          <svg className="svg-inline--fa fa-arrow-left fa-w-14" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrow-left" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg="">
          <path fill="currentColor" d="M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z">
          </path>
          </svg>
        </Link>
      </div>
    </div>
      <div className="info_infoOver">
        <div className="info_content">
          <div className="info_box">{category}</div>
          <h2>{quizCategories.coin_text} {quizCategories.win_coins} 
          <img
                title="quizpopa_coin"
                alt="coin"
                loading="lazy"
                width="19"
                height="19"
                decoding="async"
                data-nimg="1"
                srcSet="https://pngimg.com/uploads/coin/coin_PNG36871.png"
                style={{ color: 'transparent',marginLeft:"5px", marginBottom:"-3px"}}/> </h2>
        </div>
      </div>
      <div className="result-box">
        <div className="result-bg">
          <span className="percentile">
            {Math.round(( correctAnswers/ totalQuestions) * 100)}%
          </span>
          <img src={Image} alt="result" />
        </div>
        <p className="result-detail">
          Your final score is&nbsp;:&nbsp;
          <span className={finalScore/2 >= 0 ? 'positive-score' : 'negative-score'}>
              {finalScore/2 >= 0 ? `+${finalScore/2}` : finalScore/2}
          </span>
        </p> 
      </div>
      <div className="rank_smListCard">
          <ul>
            {/* <li>
              <h2>{currentRank}</h2>
              <p>Current Rank</p>
            </li> */}
            <li>
              <h2>{totalQuestions}</h2>
              <p>Total Questions</p>
            </li>
            <li>
              <h2>{allAnswers}</h2>
              <p>Correct Answer</p>
            </li>
            <li>
              <h2>{wrongAnswers}</h2>
              <p>Wrong Answer</p>
            </li>
          </ul>
        </div>      
      {/* <Link to="/home" className="new-quiz"> */}
      <p onClick={hadleclick} className="new-quiz">
          Start a new quiz!
          </p>
        {/* </Link> */}
    </div>
  );
}

export default Result;
