import React, { useRef } from 'react';
import { useLocation } from 'react-router-dom';
import Coins from "../assets/coins.png";
import quizContent from './content';
import sponsored from './sponsored.js';
import { useNavigate } from 'react-router-dom';
// import Cookies from 'js-cookie';

const Play = () => {
  const location = useLocation();
  const totalCoin = location.state.totalCoins ;
  const navigate = useNavigate(); 
  const topOfPageRef = useRef(null);

// useEffect(() => {
//   // Scroll to the top when the component mounts
//  // topOfPageRef.current.scrollIntoView({ behavior: 'smooth' });
//   // topOfPageRef.current.scrollTop= 0;
//   // window.scrollTo(0, 0);
// }, []);

  const handlePlayClick = () => {
    // Scroll to the top when "Play Now" button is clicked
    // topOfPageRef.current.scrollTop= 0;
    topOfPageRef.current.scrollIntoView({ behavior: 'auto' });
    // window.scrollTo(0, 0);
    navigate('/home', 
    { state: { totalCoin } });
  };

  return ( 
    <div className="page_container"  ref={topOfPageRef} style={{ top: 0 }} tabIndex="-1">
      <div className="playNow_playNow">
        <img src={Coins} alt= "LottieCoins Icon" className="lottie_lottieCoins"/>
        <h1>You have got {totalCoin} coins</h1>
        <p>Check out more quizzes to test your skills and keep grabbing more coins!</p>
         <button className="playNow_btn playNow_shine playNow_animated undefined" onClick={handlePlayClick}>
          Play Now
        </button>
      </div>
      <div>
        {sponsored.items.toLowerCase() === 'true' && (
          <div className="page_ad">
            <div className="page_sponsored">{sponsored.title}</div>
          </div>
        )}
      </div>
      <section className="contentList_contentList">
        <h2>{quizContent.title}</h2>
        <ul>
          {quizContent.items.map((item, index) => (
            <li key={index} style={{marginBottom:"-10px"}}>{item}</li>
          ))}
        </ul>
      </section>
    </div>
  );
};

export default Play;
