import React, { useState, useEffect } from "react";
import { useNavigate,useLocation, useParams } from "react-router-dom";
import hostInfo from '../Servies';

function Quiz() {
  const { id ,category} = useParams();
  const navigate = useNavigate();
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [isResult, setIsResult] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState();
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [isErrorMessage, setIsErrorMessage] = useState(false);
  const [score, setScore] = useState(0);
  const [level, setLevel] = useState([]);
  console.log(level)
  const [data, setData] = useState();
  const [totalCorrectAnswers, setTotalCorrectAnswers] = useState(0);
  const location = useLocation();
  const quizCategories = location.state.quizCategories;
  const timers = quizCategories.timer_value * 1000;
  const [time, setTime] = useState(timers);
  // console.log(category)

useEffect(() => {
  const fetchQuizData = async () => {
    try {
      const response = await fetch( hostInfo.hostInfo + `api/quiz-category/${id}`, {
        method: 'POST', 
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': 'quiz@234',
        },body:JSON.stringify({limit:quizCategories.num_of_questions})
      })
      if (!response.ok) {
        throw new Error(`Failed to fetch data: ${response.status} ${response.statusText}`);
      }
      const data = await response.json();
      setLevel(data.quizCategory);
      setData(data);
    } catch (error) {
      console.error("Error fetching quiz category data:", error);
    }
  };
  fetchQuizData();
}, [id]);

useEffect(() => {
  const timer = setInterval(() => {
    setTime((prevTime) => prevTime - 1000);
  }, 1000);

  if (time <= 0) {
    clearInterval(timer);
    handleTimeOver();
  } else if (time <= 10000) {
    setIsErrorMessage(true);
  } else {
    setIsErrorMessage(false);
  }

  return () => clearInterval(timer);
}, [time]);

const handleTimeOver = () => {
  setIsResult(true);
  navigate(`/${category}/result`, {
    state: {
      answers: totalCorrectAnswers/2,
      data: level,
      timeOver: true,
      score: score,
      categoryname: category,
      quizCategories: quizCategories,
    },
  });
};

const handleNextQuestion = (index) => {
  if (time <= 0) {
    handleTimeOver();
    return;
  }
  addAnswer(index);

  const selectedAnswer = level[currentQuestion].answers.split(', ')[index];
  const isCorrect = selectedAnswer === level[currentQuestion].right_ans;
  const feedbackColor = isCorrect ? 'green' : 'red';
   // Update score based on correctness
   if (isCorrect) {
    // console.log(isCorrect)
    setScore(score + quizCategories.correct_ans_point); // data correct ans point in  datbase
    setTotalCorrectAnswers(totalCorrectAnswers + 1);
  } else {
    setScore(score - quizCategories.wrong_ans_point); 
  }

  const answerLabels = document.querySelectorAll('.answer-label');
  answerLabels.forEach((label, i) => {
    const isAnswerSelected = i === index;

    if (isAnswerSelected) {
      label.style.backgroundColor = feedbackColor;
    } else if (isCorrect && level[currentQuestion].answers.split(',')[i].right_ans) {
      label.style.backgroundColor = 'green'; // Apply green color only to correct answers
    } else {
      label.style.backgroundColor = '';
    }
  });

  setTimeout(() => {
    answerLabels.forEach((label) => {
      label.style.backgroundColor = '';
    });

    if (currentQuestion >= level.length - 1) {
      setCurrentQuestion(0);
      setIsResult(true);
    } else {
      setCurrentQuestion(currentQuestion + 1);
      setSelectedIndex(); // Clear the selected index for the next question
    }
  }, 200);
};

const addAnswer = (index) => {
  const selectedAnswer =
    index !== null
      ? level[currentQuestion].answers[index]
      : {
        answer: "",
        right_ans: false,
      };
  const newAnswers = [...selectedAnswers, selectedAnswer];
  setSelectedAnswers(newAnswers);
};

  return isResult ? (
    navigate(`/${category}/result`, {
      state: {
        answers: totalCorrectAnswers/2,
        data: level,
        timeOver: time <= 0,
        score: score,
        categoryname: category,
        quizCategories: quizCategories
      },
    })
  ) : (
      <div>
      <div className="progress-box">
        <div className="progress-top">
          <div className="progress-texts">
            <h2 className="progress-title">Quiz Progress</h2>
            <p className="progress-description">
              YOU ARE SOLVING {category} QUIZ 
            </p>
          </div>
          <div className="progress-icon">
            <i className="bi bi-bar-chart"></i>
          </div>
        </div>
        <div className="progress-bottom">
          <div
            className="progress-circle"
            aria-valuemin="0"
            aria-valuemax="100"
            style={{
              "--value":
                ((currentQuestion + 1) / level.length) * 100,
            }}
          >
            <span className="progress-big">{currentQuestion + 1}</span>
            <span className="progress-mini">/{quizCategories.num_of_questions}</span>
          </div>
          <p className="progress-detail">
            You've successfully answered {currentQuestion + 1} out of {quizCategories.num_of_questions} questions.
          </p>
        </div>
      </div>
      <div className="question-box">
        <div className="question-text">
          <h2 className="question-title">Question: {currentQuestion + 1}</h2>
          <h3 className="question">
          {level && level[currentQuestion] && level[currentQuestion].question
            ? level[currentQuestion].question
            : "Loading..."}
          </h3>
        </div>
        <div
          className="progress-circle time"
          aria-valuemin="0"
          aria-valuemax="100"
          style={{ "--value": ((timers - time) / timers) * 100 }}
          >
            <span className="time">{Math.ceil(time / 1000)}</span>
    
        </div>
      </div>
    <div className="answers-boxes">

{level &&
  level[currentQuestion] &&
  level[currentQuestion].answers &&
  typeof level[currentQuestion].answers === "string" ? (
  level[currentQuestion].answers
    .split(", ")
    .map((answer, subIndex) => (
      <div key={subIndex} className="question-option">
        <label
          onClick={() => handleNextQuestion(subIndex)}
          className={`answer-label ${selectedIndex === subIndex ? 'selected' : ''} ${
            selectedIndex === subIndex && level[currentQuestion].answers.split(',')[subIndex].right_ans
              ? 'correct'
              : 'incorrect'
          }`}
        >
          {answer}
          {/* <input type="radio" name="answer" id={subIndex} className={`answer-label input${subIndex === selectedIndex ? 'selected' : ''}}`}/> */}

        </label>
      </div>
    ))
) : (
  <p>Error: Answers are not in the expected format.</p>
)}
</div>

{/* =================== */}
      {isErrorMessage ? (
        <div className="message animation">
          <div className="icon">
            <i className="bi bi-exclamation-triangle"></i>
          </div>
          <span>You must hurry up!</span>
        </div>
      ) : null}
      <div className="score-box">
        <h2 className="score-title">Your Score: {score/2}</h2>
      </div>
    </div>
  );
}

export default Quiz;


