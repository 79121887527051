import axios from 'axios';
import hostInfo from '../Servies';


const sponsored = {
  title: 'SPONSORED',
  items: 'true' // Assuming items should be a boolean
};

// Function to fetch data from the API and update the sponsored object
const fetchDataAndUpdateSponsored = async () => {
  try {
    // Make a GET request to the backend API endpoint
    const response = await axios.get( hostInfo.hostInfo +'api/allapplication_setting', {
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': 'quiz@234',
      },
    });

    // Extract the value property from the API response
    const adStatusFromApi = response.data.status.find(item => item.key === 'ad_status_on')?.value;

    if ((adStatusFromApi.toLowerCase() === 'true'||'false')) {
      sponsored.items = adStatusFromApi;
    } else {
      sponsored.items = adStatusFromApi;
    }
  } catch (error) {
    console.error('Error fetching data from API:', error.message);
  }
};

// Call the function to fetch data and update the sponsored object
fetchDataAndUpdateSponsored();

export default sponsored;

