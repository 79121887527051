import React from "react";
import ReactDOM from "react-dom/client";
import "./assets/css/style.css";
import "./assets/css/result.css";
import "./assets/css/home.css";
import "./assets/css/quiz.css";
import "./assets/css/intro.css";
import "./assets/css/play.css"
import "./assets/css/playnow.css";
import "./assets/css/blog.css";

import { QuizProvider } from "./context/QuizContext";

import App from "./App";
import { BrowserRouter } from "react-router-dom";

// // Service Worker registration
// if ('serviceWorker' in navigator) {
//   navigator.serviceWorker.register("/service-worker.js")
//   .then(function(registration) {
//       console.log('Service Worker registered with scope:', registration.scope);
//     })
//     .catch(function(error) {
//       console.error('Service Worker registration failed:', error);
//     });
// }

// // Request notification permission
// Notification.requestPermission().then(function(permission) {
//   if (permission === 'granted') {
//     console.log('Notification permission granted');
//     // Subscribe to push notifications
//     navigator.serviceWorker.ready
//       .then(function(registration) {
//         return registration.pushManager.subscribe({
//           userVisibleOnly: true,
//           applicationServerKey: 'quiz@123'
//         });
//       })
//       .then(function(subscription) {
//         console.log('Push subscription successful:', subscription);
//         // Send the subscription information to your server for later use
//       })
//       .catch(function(error) {
//         console.error('Push subscription failed:', error);
//       });
//   } else {
//     console.warn('Notification permission denied');
//   }
// });

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
     <QuizProvider>
      <App />
      </QuizProvider>
  </BrowserRouter>
);
