const content = {
    title: "Play Quiz and Win Coins!",
    items: [
      "Get coins on the completion of each quiz.",
      "Upgrade your skills with our top quizzes.",
      "We have many top trending categories like Cricket, World, India, Business, loan, insurance & many more!",
      "Millions of quiz admirers like you showed trust on us.",
      "Challenge lakhs of players from across the world!",
    ],
  };
  
  export default content;