import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import sponsored from './sponsored';
import hostInfo from '../Servies';
import BlogPlaceholder from "../assets/BlogPlaceholder.png";

const BlogPage = () => {
    const {title}=useParams();
    const [articles, setArticles] = useState(null);
     console.log(title)
   
    useEffect(() => {
      // Fetch articles by category from your API with API key in headers
      const fetchArticle = async () => {
        try {
          const response = await fetch(`${hostInfo.hostInfo}api/articles/${title}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'x-api-key': 'quiz@234',
            },
          });
    
          if (response.ok) {
            const data = await response.json();
            console.log(data);
            setArticles(data.articlesbycategory);
          } else {
            console.error('Error fetching data:', response.status);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
    
      fetchArticle();
}, [title]);

    const getBlogPlaceholder = (imagename) => {
      if (imagename && imagename.includes("http")) {
        return imagename;
      } else {
        return BlogPlaceholder;
      }
    };

  return (
    <div className="blog-page">
        {articles && articles.map((article) => (
    
        <div key={article.id} style={{border: "2px solid #342b79", boxShadow: "1px 1px 10px #888888" ,padding:"10px 10px",marginTop:"15px",marginBottom:"15px"}}>
      <h3 style={{color:" #f27c2c", fontWeight:"bold"}}>{article.article_title}</h3>

      <img 
        src={article.article_image ? getBlogPlaceholder (article.article_image) : BlogPlaceholder }
        // src={article.article_image}
        alt={article.article_title} class="kg-image" loading="lazy" width="2000" height="1125" 
        sizes="(min-width: 720px) 720px" style={{maxWidth: "100%", height: "auto", margin: "20px 0px"}}>
      </img>

      <div className="blog-content" >
        <p className="p">
        <div dangerouslySetInnerHTML={{ __html: article.paragraph1 }} />
        </p>

        {/* Ad Section */}
        <div>
          {sponsored.items.toLowerCase() === 'true' && (
        <div className="page_ad">
          <div className="page_sponsored">{sponsored.title}</div>
        </div>
          )}
        </div>  

        <p className="p">
        {/* {article.paragraph2} */}
        <div dangerouslySetInnerHTML={{ __html: article.paragraph2 }} />
        </p>


        <p className="p">
        {/* {article.paragraph3} */}
        <div dangerouslySetInnerHTML={{ __html: article.paragraph3 }} />
        </p>
      </div>
    </div>
    ))}
    </div>
  );
};

export default BlogPage;
